import React from 'react';
import styles from './style.module.scss';
import Link from 'next/link';
import WsStaticImage from '@/components/common/WsStaticImage';

interface LargeDecoratingProps {
  items?: any;
}
const LargeDecorating: React.FC<LargeDecoratingProps> = ({ items }) => {
  return (
    <section className={styles.exploresofasection} style={items?.style}>
      <div className="container-1600">
        <div className="wdRow ">
          <div className="wdCol-lg-12">
            <div className={styles.exploresofaheading}>
              <div className={styles['subtitle']}>{items.title}</div>
              <p>{items.description}</p>
            </div>
          </div>
        </div>
        <div className={styles.exploresofacontent}>
          <div className="wdRow wdRow-cols-2 wdRow-cols-sm-2 wdRow-cols-md-3 wdRow-cols-lg-5 ">
            {items?.items?.map((list: any, index: number) => (
              <div className="wdCol" key={index}>
                <Link href={list.href} className={styles.exploresofacard}>
                  <div className={styles.exploresofaimg}>
                    <figure>
                      <WsStaticImage
                        src={list.image}
                        alt={list.alt}
                        style={{ width: '100%' }}
                        width={500}
                        height={500}
                        quality={100}
                        loading="lazy"
                        // sizes="100vw"
                      />
                    </figure>
                  </div>
                  <div className={styles.exploresofatext}>
                    <p className={styles['prices']}>{list.title}</p>
                    <p>{list.label}</p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LargeDecorating;
