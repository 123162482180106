import React from 'react';
import styles from './style.module.scss';
import Link from 'next/link';
import WsStaticImage from '@/components/common/WsStaticImage';

interface LargeDecoratingProps {
  items: any;
}

const chunkArray = (arr: any[], chunkSize: number) => {
  const result = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
};
const LargeDecoratingMobile: React.FC<LargeDecoratingProps> = ({ items }) => {
  const chunks = chunkArray(items?.items || [], 2);
  return (
    <>
      <section className={styles.exploresofasection} style={items?.style}>
        <div className={styles.exploresofainner}>
          <div className="wdRow ">
            <div className="wdCol-lg-12">
              <div className={styles.exploresofaheading}>
                <div className={styles['subtitle']}>{items.title}</div>
                <p>{items.description}</p>
              </div>
            </div>
          </div>
          <div className={styles.exploresofacontent}>
            {/* {items?.items?.map((list: any, index: number) => (
              <Link
                href={list.href}
                className={styles.exploresofacard}
                key={index}
              >
                <div className={styles.exploresofaimg}>
                  <figure>
                    <WsStaticImage
                      src={list.image}
                      alt={list.alt}
                      width={500}
                      height={500}
                      quality={100}
                      loading="lazy"
                      style={{ width: '100%' }}
                    // sizes="100vw"
                    />
                  </figure>
                </div>
                <div className={styles.exploresofatext}>
                  <p className={styles['prices']}>{list.title}</p>
                  <p>{list.label}</p>
                </div>
              </Link>
            ))} */}
            {chunks.map((chunk, rowIndex) => (
              <div key={rowIndex} className={styles.row}>
                {chunk.map((list: any, index: number) => (
                  <Link
                    href={list.href}
                    className={styles.exploresofacard}
                    key={index}
                  >
                    <div className={styles.exploresofaimg}>
                      <figure>
                        <WsStaticImage
                          src={list.image}
                          alt={list.alt}
                          width={500}
                          height={500}
                          quality={100}
                          loading="lazy"
                          style={{ width: '100%' }}
                        />
                      </figure>
                    </div>
                    <div className={styles.exploresofatext}>
                      <p className={styles['prices']}>{list.title}</p>
                      <p>{list.label}</p>
                    </div>
                  </Link>
                ))}
              </div>
            ))}
          </div>
          {items.viewMore && (
            <div className={styles.viewmorebtndiv}>
              <Link
                href={items.viewMore}
                className={`${styles['view-all-furni']} btn view-all-btn`}
              >
                View More Categories
                <WsStaticImage
                  src="/images/home-new1/ArrowRight.svg"
                  alt="ArrowRight"
                  width={24}
                  height={20}
                  quality={75}
                  loading="lazy"
                />
              </Link>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default LargeDecoratingMobile;
