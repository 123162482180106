import React from 'react';
import styles from './style.module.scss';
import WsStaticImage from '@/components/common/WsStaticImage';

interface MidBannerProps {
  items?: any;
  slug?: any;
  isMobile?: any;
}
const MidBanner: React.FC<MidBannerProps> = ({ items, isMobile }) => {
  const item = items;
  return (
    <section className={styles.sofadiscountsection}>
      <div className="container-1600">
        <div className={styles.discountimg}>
          <WsStaticImage
            src={isMobile ? item.mimage : item.image}
            alt={item.alt}
            width={1240}
            height={97}
            quality={75}
          />
        </div>
      </div>
    </section>
  );
};

export default MidBanner;
