import React from 'react';
import styles from './style.module.scss';
import Link from 'next/link';
// import dynamic from 'next/dynamic';
// import { useSelector } from 'react-redux';
// import { getRequestedSettings } from '@/store/features/commonSlice';
// import { getSettingByKeyValue, updateImageVersion } from '@/utils/common';
// import WsStaticImage from '@/components/common/WsStaticImage';
import WsFillImage from '@/components/common/WsFillImage';
import Description from './description';

// const Description = dynamic(() => import('./description'), { ssr: false });

interface FinestBrandProps {
  items: any;
}
const FinestBrand: React.FC<FinestBrandProps> = ({ items }) => {
  return (
    <>
      <section className={styles.finestbrandsection}>
        <div className="container-fluid">
          <div className="wdRow ">
            <div className="wdCol-lg-12">
              <div className={styles.finestbrandheading}>
                <div className={styles.title}>{items.title}</div>
                <Description description={items?.description} />
              </div>
            </div>
          </div>
          <div className={styles.finestbrandcontent}>
            <div className="wdRow wdgx-lg-4 wdgx-md-3">
              {items?.items?.map((poster: any, index: number) => (
                <div
                  className="wdCol-lg-4 wdCol-md-4 wdCol-sm-6 wdCol-12"
                  key={index}
                >
                  <Link
                    href={poster.href}
                    className={styles.finestbrandcard}
                    aria-label={poster.alt}
                  >
                    <div className={styles.finestbrandimg}>
                      <figure>
                        <WsFillImage
                          src={poster.image}
                          alt={poster.alt}
                          title={poster.alt}
                          loading="lazy"
                          quality={100}
                        />
                      </figure>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FinestBrand;
