import React from 'react';
import styles from './style.module.scss';
import Link from 'next/link';
import WsStaticImage from '@/components/common/WsStaticImage';

interface SeatingEssentialProps {
  items: any;
}

const SeatingEssentialMobile: React.FC<SeatingEssentialProps> = ({ items }) => {
  return (
    <>
      <section className={styles.shopsofasizesection}>
        <div className={styles.shopsofasizeinner}>
          <div className="container-1240 px-sm-0">
            <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={styles.shopsofasizeheading}>
                  <div className={styles['head-title']}>{items.title}</div>
                  <p className={styles['sub-title']}>{items?.description}</p>
                </div>
              </div>
            </div>
            <div className={styles.shopsofasizecontant}>
              <div
                className={`${styles.shopbox} ${styles['grid-container-4-col']}`}
              >
                {items?.items?.map((collection: any, index: number) => (
                  <Link
                    href={collection.href}
                    className={styles.shopsofasizecard}
                    key={index}
                  >
                    <figure>
                      <WsStaticImage
                        quality={100}
                        src={collection?.image}
                        alt={collection.alt}
                        width={500}
                        height={500}
                        loading="lazy"
                        sizes="100vw"
                      />
                    </figure>

                    {/* <p className={styles['col-name']}>{collection.name}</p>
                    
                    <p className={styles['price']}>
                      {collection.startingPrice}
                    </p> */}

                    {collection.label ? (
                      typeof collection.label === 'object' ? (
                        <>
                          <p className={styles['col-name']}>
                            {collection.title}
                          </p>
                          <p
                            className={styles['price']}
                            style={{ textAlign: 'left' }}
                          >
                            {collection.label.price && (
                              <>
                                {collection.label.price}{' '}
                                <del className={styles.offprice}>
                                  {collection.label.mrp}
                                </del>
                                <span className={styles.discountoff}>
                                  {collection.label.discount}
                                </span>
                              </>
                            )}
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            className={styles['col-name']}
                            style={{ textAlign: 'center' }}
                          >
                            {collection.title}
                          </p>
                          <p
                            className={styles['price']}
                            style={{ textAlign: 'center' }}
                          >
                            {collection.label}
                          </p>
                        </>
                      )
                    ) : (
                      <p
                        className={styles['col-name']}
                        style={{ textAlign: 'center' }}
                      >
                        {collection.title}
                      </p>
                    )}
                  </Link>
                ))}
              </div>
            </div>
            {items.viewMore && (
              <div className={styles.viewmorebtndiv}>
                <Link
                  href={items.viewMore}
                  className={`${styles['view-all-furni']} btn view-all-btn`}
                >
                  View More Categories
                  <WsStaticImage
                    src="/images/home-new1/ArrowRight.svg"
                    alt="ArrowRight"
                    width={24}
                    height={20}
                    quality={75}
                    loading="lazy"
                  />
                </Link>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default SeatingEssentialMobile;
