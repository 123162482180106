import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import Link from 'next/link';
import WsStaticImage from '@/components/common/WsStaticImage';

// interface PopularSofaProps {
//   data: any; // Replace `any` with the expected type of data
//   slug: any;
// }
// const PopularSofa: React.FC<PopularSofaProps> = ({ data, slug }) => {
//   const title = slug
//     ?.replace(/-/g, ' ')
//     .replace(/\b\w/g, (char: string) => char?.toUpperCase());
//   const [tabData, setTab] = useState<any[]>([]);
//   const [activeTab, setActiveTab] = useState(0);
//   const dispatch = useDispatch<AppDispatch>();
//   useEffect(() => {
//     dispatch(fetchCategoriesProducts({ categoryId: data.items })).then(
//       (response: any) => {
//         let data: any[] = [];
//         if (response?.payload?.length > 0) data = response?.payload;
//         const topProducts = [];
//         for (let i = 0; i < response?.payload.length; i++) {
//           const top = response?.payload[i]?.topProducts;
//           for (let l = 0; l < 2; l++) {
//             topProducts.unshift(top[l]);
//             if (topProducts.length === 8) break;
//           }
//           if (topProducts.length === 8) break;
//         }
//         data.unshift({
//           products: topProducts,
//           category: { name: `All ${title?.split(' ')[0]}s` },
//         });
//         setTab(data);
//       }
//     );
//   }, [dispatch]);
//   const handleTabChange = (e: any, index: number) => {
//     e.preventDefault();
//     setActiveTab(index);
//   };

//   // const tabData: any = data?.items?.[activeTab];
//   return (
//     <>
//       <section className={styles.populrsofasection}>
//         <div className={styles.populrsofainner}>
//           <div className="wdRow ">
//             <div className="wdCol-lg-12">
//               <div className={styles.populrsofaheading}>
//                 <h2>{data?.title ?? `Popular Picks in ${title}`}</h2>
//               </div>
//             </div>
//           </div>
//           <div className={styles.populrsofacontent}>
//             <div className="wdRow ">
//               <div className="wdCol-lg-12">
//                 <div className={styles.tabstop}>
//                   <ul className="sofa-tabs">
//                     {tabData?.map((item: any, index: any) => (
//                       <li key={index}>
//                         <Link
//                           href="#"
//                           className={`tab-btn ${activeTab == index ? 'active' : ''}`}
//                           onClick={(e) => handleTabChange(e, index)}
//                         >
//                           {item?.category?.name}
//                         </Link>
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               </div>
//             </div>
//             <div className="tab-content">
//               <div className="wdRow wdgx-3">
//                 {tabData?.[activeTab]?.products?.map(
//                   (product: any, index: any) => (
//                     <div
//                       className="wdCol-lg-3 wdCol-md-3 wdCol-sm-6 wdCol-12"
//                       key={index}
//                     >
//                       <Link
//                         href={product.product_detail.url}
//                         className={styles.populrsofacard}
//                       >
//                         <div className={styles.populrsofaimg}>
//                           <WsImage
//                             src={product.product_detail.image}
//                             // src={`${imageBaseUrl?.replaceAll('cache/', '')}${product.product_detail.imgSrc}`}
//                             alt={product.product_detail.alt}
//                             title={product.product_detail.name}
//                             width={500}
//                             height={500}
//                             quality={75}
//                             data-was-processed="true"
//                             key={product?.category_id}
//                           />
//                         </div>
//                         <div className={styles.populrsofatext}>
//                           <p>{product.product_detail.name}</p>
//                           <h4>
//                             {formatCurrency(
//                               product.product_detail.special_price
//                             )}{' '}
//                             <del className={styles.offprice}>
//                               {formatCurrency(product.product_detail.price)}
//                             </del>
//                             <span className={styles.discountoff}>
//                               {(
//                                 ((product.product_detail.price -
//                                   product.product_detail.special_price) /
//                                   product.product_detail.price) *
//                                 100
//                               ).toFixed(0)}
//                               % off
//                             </span>
//                           </h4>
//                         </div>
//                       </Link>
//                     </div>
//                   )
//                 )}
//               </div>
//               <div className="wdRow ">
//                 <div className="wdCol-lg-12">
//                   <div className={styles.viewsofabutton}>
//                     <Link
//                       href={`/${title?.split(' ')[0]}s`}
//                       className="btn secondary-btn"
//                     >
//                       {`View All ${title?.split(' ')[0]}s`}
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

interface PopularSofaProps {
  items: any;
  isMobile: any;
}

// const chunkArray = (arr: any[], chunkSize: number) => {
//   const result = [];
//   for (let i = 0; i < arr.length; i += chunkSize) {
//     result.push(arr.slice(i, i + chunkSize));
//   }
//   return result;
// };
const PopularSofa: React.FC<PopularSofaProps> = ({ items, isMobile }) => {
  const tabList = items.items;
  const [activeTab, setActiveTab] = useState(0);
  const [firstHalf, setFirstHalf] = useState([]);
  const [secondHalf, setSecondHalf] = useState([]);
  // const chunks = chunkArray(items?.items || [], 2);

  const handleTabChange = (e: React.MouseEvent, index: number) => {
    e.preventDefault();
    setActiveTab(index);
  };
  useEffect(() => {
    const items = tabList[activeTab]?.items || [];
    const midpoint = Math.ceil(items.length / 2);
    const firstHalf = items.slice(0, midpoint);
    const secondHalf = items.slice(midpoint);
    setFirstHalf(firstHalf);
    setSecondHalf(secondHalf);
  }, [activeTab]);

  return (
    <>
      <section className={styles.populrsofasection}>
        <div className="container-1600">
          <div className="wdRow ">
            <div className="wdCol-lg-12">
              <div className={styles.populrsofaheading}>
                <h2 className={styles.title}>{items?.title}</h2>
                <p className={styles.subtitle}>
                  Design Your Home Decor, Your Way
                </p>
              </div>
            </div>
          </div>
          <div className={styles.populrsofacontent}>
            <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={styles.tabstop}>
                  <ul className="sofa-tabs">
                    {tabList?.map((item: any, index: any) => (
                      <li key={index}>
                        <Link
                          href="#"
                          className={`tab-btn ${activeTab == index ? 'active' : ''}`}
                          onClick={(e) => handleTabChange(e, index)}
                        >
                          {item.tabName}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="tab-content">
              {!isMobile ? (
                <div className="wdRow wdgx-3">
                  {tabList[activeTab]?.items?.map(
                    (childList: any, index: number) => (
                      <div
                        className="wdCol-lg-3 wdCol-md-3 wdCol-sm-6 wdCol-12"
                        key={index}
                      >
                        <Link
                          href={childList?.href}
                          className={styles.populrsofacard}
                        >
                          <div className={styles.populrsofaimg}>
                            <WsStaticImage
                              src={childList.image}
                              alt={childList.alt}
                              key={childList.image}
                              width={500}
                              height={500}
                              quality={75}
                              data-was-processed="true"
                            />
                          </div>

                          <div className={styles.populrsofatext}>
                            <p>{childList.title}</p>
                            {childList.label &&
                              (typeof childList.label === 'object' ? (
                                <h4>
                                  {childList.label.price && (
                                    <>
                                      {childList.label.price}{' '}
                                      <del className={styles.offprice}>
                                        {childList.label.mrp}
                                      </del>
                                      <span className={styles.discountoff}>
                                        {childList.label.discount}
                                      </span>
                                    </>
                                  )}
                                </h4>
                              ) : (
                                <h4>{childList.label}</h4>
                              ))}
                          </div>
                        </Link>
                      </div>
                    )
                  )}
                </div>
              ) : (
                <div className={styles.row}>
                  <div>
                    {firstHalf?.map((childList: any, index: number) => (
                      <div className={styles.col} key={index}>
                        <Link
                          href={childList?.href}
                          className={styles.populrsofacard}
                        >
                          <div className={styles.populrsofaimg}>
                            <WsStaticImage
                              src={childList.image}
                              alt={childList.alt}
                              key={childList.image}
                              width={500}
                              height={500}
                              quality={75}
                              data-was-processed="true"
                            />
                          </div>

                          <div className={styles.populrsofatext}>
                            <p>{childList.title}</p>
                            {childList.label &&
                              (typeof childList.label === 'object' ? (
                                <h4>
                                  {childList.label.price && (
                                    <>
                                      {childList.label.price}{' '}
                                      <del className={styles.offprice}>
                                        {childList.label.mrp}
                                      </del>
                                      <span className={styles.discountoff}>
                                        {childList.label.discount}
                                      </span>
                                    </>
                                  )}
                                </h4>
                              ) : (
                                <h4>{childList.label}</h4>
                              ))}
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                  <div>
                    {secondHalf?.map((childList: any, index: number) => (
                      <div className={styles.col} key={index}>
                        <Link
                          href={childList?.href}
                          className={styles.populrsofacard}
                        >
                          <div className={styles.populrsofaimg}>
                            <WsStaticImage
                              src={childList.image}
                              alt={childList.alt}
                              key={childList.image}
                              width={500}
                              height={500}
                              quality={75}
                              data-was-processed="true"
                            />
                          </div>

                          <div className={styles.populrsofatext}>
                            <p>{childList.title}</p>
                            {childList.label &&
                              (typeof childList.label === 'object' ? (
                                <h4>
                                  {childList.label.price && (
                                    <>
                                      {childList.label.price}{' '}
                                      <del className={styles.offprice}>
                                        {childList.label.mrp}
                                      </del>
                                      <span className={styles.discountoff}>
                                        {childList.label.discount}
                                      </span>
                                    </>
                                  )}
                                </h4>
                              ) : (
                                <h4>{childList.label}</h4>
                              ))}
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {items?.moreView && (
                <div className="wdRow">
                  <div className="wdCol-lg-12">
                    <div className={styles.viewsofabutton}>
                      <Link
                        href={tabList[activeTab]?.moreView}
                        className="btn secondary-btn"
                      >
                        View More
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PopularSofa;
